// Generated by Framer (33d5032)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jVNgIt2Wp"];

const serializationHash = "framer-qBugw"

const variantClassNames = {jVNgIt2Wp: "framer-v-1k1ewll"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "jVNgIt2Wp", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1k1ewll", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"jVNgIt2Wp"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SXRhbGlhbmEtcmVndWxhcg==", "--framer-font-family": "\"Italiana\", \"Italiana Placeholder\", serif", "--framer-font-size": "20px"}}>Savor every conversation. 🍕🍷📚</motion.p></React.Fragment>} className={"framer-1wysrq8"} fonts={["GF;Italiana-regular"]} layoutDependency={layoutDependency} layoutId={"CON3UFmRI"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qBugw.framer-g0n7k5, .framer-qBugw .framer-g0n7k5 { display: block; }", ".framer-qBugw.framer-1k1ewll { height: 24px; overflow: hidden; position: relative; width: 278px; }", ".framer-qBugw .framer-1wysrq8 { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 278
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerEZFn1jSMt: React.ComponentType<Props> = withCSS(Component, css, "framer-qBugw") as typeof Component;
export default FramerEZFn1jSMt;

FramerEZFn1jSMt.displayName = "Savor every conversation";

FramerEZFn1jSMt.defaultProps = {height: 24, width: 278};

addFonts(FramerEZFn1jSMt, [{explicitInter: true, fonts: [{family: "Italiana", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/italiana/v20/QldNNTtLsx4E__B0XTmUY31Wx7Vv.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})